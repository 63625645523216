.form-control {
    border-color: black !important;
}

.form-control:focus {
    border: 3px solid black !important;
}

.card__item {
    cursor: pointer;
}

.swiper-container {
    height: 100%;
}

.swiper-slide {
    height: 100%;
}

.card__item {
    height: 100%;
}

.card__img img {
    object-fit: cover;
    height: 30vh;
    width: 100%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
