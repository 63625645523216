.linkReg {
    border-bottom: 1px solid black;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    cursor: pointer;
}

.user__img {
    max-height: 120px;
    max-width: 120px;
    border: 3px solid black;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

thead th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}