.thumbs {
    opacity: .99;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.thumbs:before {
    content: '';
    background: -webkit-linear-gradient(top, transparent 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, transparent 0%, rgb(0 0 0) 100%);
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.thumbs img {
    display: block;
    width: 100%;
    /* ширина картинки */
    height: auto;
    /* высота картинки */
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.thumbs .caption__photo {
    width: 100%;
    padding: 20px;
    color: #fff;
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    z-index: 3;
    text-align: center;
}

.thumbs .caption_price {
    position: absolute;
    top: 80%;
    left: 10px;
    color: white;
    z-index: 4;
}

.thumbs .caption__photo span {
    display: block;
    opacity: 0;
    position: relative;
    top: 100px;
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.thumbs .caption__photo .caption__title {
    line-height: 1;
    font-weight: normal;
    font-size: 18px;
}

.thumbs .caption__photo .caption__info {
    line-height: 1.2;
    margin-top: 5px;
    font-size: 12px;
}

.thumbs:focus:before,
.thumbs:focus span,
.thumbs:hover:before,
.thumbs:hover span {
    opacity: 1;
}

.thumbs:focus:before,
.thumbs:hover:before {
    top: 0;
}

.thumbs:focus span,
.thumbs:hover span {
    top: 0;
}

.thumbs:focus .caption__title,
.thumbs:hover .caption__title {
    -webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

.thumbs:focus .caption__info,
.thumbs:hover .caption__info {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}